import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Linkedin, Mail } from 'react-feather'
import Layout from '../components/Layout'
import { isGatsbyImage, trunc } from '../utils/helpers'

const SHORT_DESCRIPTION_LENGTH = 314

function shortenDescription(description) {
  return trunc(description, SHORT_DESCRIPTION_LENGTH)
}

const TeamMember = ({ person }) => {
  const [expanded, setExpanded] = useState(false)
  const isDescriptionLong = person.description.length > SHORT_DESCRIPTION_LENGTH

  return (
    <div key={person.id} className="has-border is-paddingless">
      {person.image && isGatsbyImage(person.image) ? (
        <BackgroundImage
          fluid={person.image.localFile.childImageSharp.fluid}
          className="profile-image-container"
        />
      ) : (
        person.image && (
          <div
            className="profile-image-container"
            style={{ backgroundImage: `url(${person.image.url})` }}
          />
        )
      )}
      <div className="p-8 is-vcentered">
        <h4 className="mb-4 ta-c">{person.name}</h4>
        <p className="mb-4 italic ta-c">{person.position}</p>
        <p className={`description ${isDescriptionLong ? 'mb-0' : ''}`}>
          {expanded
            ? person.description
            : shortenDescription(person.description)}
        </p>
        {isDescriptionLong && (
          <div className="mb-em">
            <span
              role="button"
              tabIndex="-1"
              className="expand-text-btn"
              aria-label="Expand description"
              onKeyDown={() => null}
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? 'Show less' : 'Read more'}
            </span>
          </div>
        )}
        {person.socialMedia && (
          <div className="mt-3 flex flex-row items-center space-x-2">
            {person.socialMedia.linkedin && (
              <a
                href={person.socialMedia.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`${person.name}'s LinkedIn Page`}
              >
                <Linkedin />
              </a>
            )}
            {person.socialMedia.email && (
              <a
                href={`mailto:${person.socialMedia.email}`}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`${person.name}'s Email`}
              >
                <Mail />
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
export const TeamTemplate = ({ team }) => (
  <div role="main">
    <section className="section">
      <div className="container">
        <div className="content">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            {team.map(person => (
              <TeamMember key={person.id} person={person} />
            ))}
          </div>
        </div>
      </div>
    </section>
  </div>
)

TeamTemplate.propTypes = {
  acf: PropTypes.shape({ hero: PropTypes.object }),
}

const TeamPage = ({ data }) => {
  const {
    wordpressPage: { title, acf, featured_media: featuredMedia },
    allWordpressAcfOptions: { edges },
  } = data

  return (
    <Layout
      header={acf.header}
      seo={{ pageTitle: title, pageDescription: acf.description }}
      featuredMedia={featuredMedia}
      lightText
    >
      <TeamTemplate acf={acf} team={edges[0].node.options.team} />
    </Layout>
  )
}

TeamPage.propTypes = {
  data: PropTypes.shape({
    wordpressPage: PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      featured_media: PropTypes.object,
      acf: PropTypes.object,
    }),
  }),
}

export default TeamPage

export const pageQuery = graphql`
  query TeamPageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      id
      title
      content
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxHeight: 865) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        title
        caption
      }
      acf {
        header {
          title
          subtitle
        }
        description
      }
    }
    allWordpressAcfOptions {
      edges {
        node {
          options {
            team {
              id: rowID
              name
              position
              description
              socialMedia {
                linkedin
                email
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxHeight: 1080) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
