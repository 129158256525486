import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import PageBuilder from '../blocks'
import Layout from '../components/Layout'
import Solution from '../components/Solution'
import WithCallToAction from '../components/WithCallToAction'
import { extractPathFromWordpressUrl } from '../utils/helpers'

// TODO: add prop types to page and post to require blocks

export const PageTemplate = ({
  acf,
  blocks,
  content,
  meta,
  isSolution,
  isPreview = false,
  path,
  solutions = [],
}) => {
  const filteredSolutions = solutions.filter(
    solution =>
      solution.isFeatured &&
      solution.category === acf.category &&
      extractPathFromWordpressUrl(solution.featuredPage) != path
  )
  // limit length to 3
  filteredSolutions.length =
    filteredSolutions.length > 3 ? 3 : filteredSolutions.length

  return (
    <WithCallToAction
      catchPhrase={acf.ctaPhrase || 'Ready to take the next steps?'}
    >
      <div
        role="main"
        className={acf.header && acf.header.title.length > 0 ? '' : 'mt-81'}
      >
        <PageBuilder
          blocks={blocks.filter(block => block.blockName !== null)}
          content={content}
          meta={meta}
        />
        {!isPreview && isSolution && filteredSolutions.length > 0 && (
          <section id="related-solutions" className="section">
            <div className="container content">
              <h2>Related Solutions</h2>
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                {filteredSolutions.map(solution => (
                  <Solution
                    key={solution.id}
                    solution={solution}
                    variant="with-image-bg"
                    displayFeaturedTag={false}
                  />
                ))}
              </div>
            </div>
          </section>
        )}
      </div>
    </WithCallToAction>
  )
}

const Page = ({ data }) => {
  const {
    wordpressPage: {
      title,
      blocks,
      path,
      content,
      acf,
      featured_media: featuredMedia,
      meta,
    },
    allWordpressAcfOptions: { edges: optionsEdges },
  } = data

  const {
    node: {
      options: { solutions },
    },
  } = optionsEdges[0]

  return (
    <Layout
      seo={{
        pageTitle: title,
        pageDescription: acf.description,
      }}
      header={acf.header && acf.header.title.length > 0 ? acf.header : null}
      featuredMedia={featuredMedia}
      lightText={acf.header && acf.header.title.length > 0}
    >
      <PageTemplate
        content={content}
        acf={acf}
        featuredMedia={featuredMedia}
        blocks={blocks}
        meta={meta}
        path={path}
        isSolution={acf.type === 'Solution'}
        solutions={solutions}
      />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.shape({
    wordpressPage: PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      featured_media: PropTypes.object,
      acf: PropTypes.object,
    }),
  }),
}

export default Page

export const pageQuery = graphql`
  query PageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      id
      title
      path
      content
      blocks {
        ...WPBlock
        innerBlocks {
          ...WPInnerBlock
        }
      }
      meta {
        media
      }
      featured_media {
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        title
        caption
      }
      acf {
        header {
          title
          subtitle
        }
        ctaPhrase
        description
        type
        category
      }
    }
    allWordpressAcfOptions {
      edges {
        node {
          options {
            solutions {
              ...Solution
            }
          }
        }
      }
    }
  }
`
