import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import PropTypes from 'prop-types'
import React from 'react'
import { ArrowRight } from 'react-feather'
import PageBuilder from '../blocks'
import { Announcements } from '../components/Announcement'
import ContactUs from '../components/ContactUs'
import Layout from '../components/Layout'
import { extractPathFromWordpressUrl } from '../utils/helpers'

export const LandingPageTemplate = ({ acf, blocks, meta, content }) => {
  const { headerVideoMP4, headerVideoWebM, headerImage } = acf.headerVideo
  const { solutions } = acf

  const gatsbyFiles = !!(
    headerVideoMP4.localFile &&
    headerVideoWebM.localFile &&
    headerImage.localFile
  )

  const LandingPageVideo = (
    <video id="video" muted loop autoPlay preload="auto" data-testid={2}>
      {gatsbyFiles ? (
        <React.Fragment>
          <source
            type={headerVideoMP4.localFile.internal.mediaType}
            src={headerVideoMP4.localFile.url}
          />
          <source
            type={headerVideoWebM.localFile.internal.mediaType}
            src={headerVideoWebM.localFile.url}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <source type={headerVideoMP4.mime_type} src={headerVideoMP4.url} />
          <source type={headerVideoWebM.mime_type} src={headerVideoWebM.url} />
        </React.Fragment>
      )}
    </video>
  )

  return (
    <div
      className="is-landing"
      role="main"
      aria-label="Landing page"
      aria-labelledby="video-header"
    >
      {gatsbyFiles ? (
        <BackgroundImage
          className="video-bg"
          fluid={headerImage.localFile.childImageSharp.fluid}
        >
          <div className="video-bg-overlay">
            <div className="video-bg-overlay-container">
              <h1
                id="video-header"
                className="overlay-title is-size-4-mobile has-text-white ta-c"
              >
                {acf.header && acf.header.title}
              </h1>
            </div>
            <div className="blocks">
              <div className="block--large">
                <h2 className="title is-size-3-desktop is-size-4-tablet is-size-4-mobile has-text-white m-auto">
                  What kind of solutions does your business need?
                </h2>
              </div>
              <div className="blocks-inner">
                {solutions.map(solution => (
                  <a
                    key={solution.page}
                    className="block--with-link"
                    href={extractPathFromWordpressUrl(solution.page)}
                  >
                    <div className="block--bg-inner">
                      <h4 className="subtitle is-size-4-widescreen is-size-5-desktop is-size-6-tablet is-size-6-mobile mtb-auto is-link">
                        {solution.title}
                      </h4>
                      <div className="mtb-auto block-link-icon">
                        <ArrowRight
                          className="block-link-icon is-link"
                          size={16}
                        />
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
          {LandingPageVideo}
        </BackgroundImage>
      ) : (
        <div
          className="video-bg"
          style={{ backgroundImage: `url${headerImage.url}` }}
        >
          <div className="video-bg-overlay">
            <div className="video-bg-overlay-container">
              <h1
                id="video-header"
                className="overlay-title is-size-4-mobile has-text-white ta-c"
              >
                {acf.header && acf.header.title}
              </h1>
            </div>
            <div className="blocks">
              <div className="block--large">
                <h2 className="title is-size-3-desktop is-size-4-tablet is-size-4-mobile has-text-white m-auto">
                  What kind of solutions does your business need?
                </h2>
              </div>
              <div className="blocks-inner">
                {solutions.map(solution => (
                  <a
                    key={solution.page}
                    className="block--with-link"
                    href={extractPathFromWordpressUrl(solution.page)}
                  >
                    <div className="block--bg-inner">
                      <h4 className="subtitle is-size-4-widescreen is-size-5-desktop is-size-6-tablet is-size-6-mobile mtb-auto is-link">
                        {solution.title}
                      </h4>
                      <div className="mtb-auto block-link-icon">
                        <ArrowRight
                          className="block-link-icon is-link"
                          size={16}
                        />
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          </div>
          {LandingPageVideo}
        </div>
      )}
      <PageBuilder content={content} blocks={blocks} meta={meta} />
      <ContactUs />
    </div>
  )
}

const Page = ({ data }) => {
  const {
    wordpressPage: { content, acf, blocks, meta },
    allWordpressPost: { edges },
    site: {
      siteMetadata: { landingPageTitle },
    },
  } = data

  let hasAnnouncements = !!(acf.announcements && acf.announcements.length)
  const filteredAnnouncements = hasAnnouncements
    ? acf.announcements.filter(a => a.title !== 'do not delete')
    : []

  hasAnnouncements = !!filteredAnnouncements.length

  return (
    <>
      {hasAnnouncements && (
        <Announcements announcements={filteredAnnouncements} />
      )}
      <Layout
        seo={{
          pageTitle: landingPageTitle,
          isLandingPage: true,
        }}
        lightText
        navbarContainerStyles={{
          marginTop: hasAnnouncements ? '60px' : 0,
        }}
      >
        <LandingPageTemplate
          content={content}
          acf={acf}
          posts={edges}
          blocks={blocks}
          meta={meta}
        />
      </Layout>
    </>
  )
}

Page.propTypes = {
  data: PropTypes.shape({
    wordpressPage: PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      featured_media: PropTypes.object,
      acf: PropTypes.object,
    }),
  }),
}

export default Page

export const pageQuery = graphql`
  fragment VideoFields on File {
    url
    internal {
      mediaType
    }
  }
  query LandingPageById($id: String!) {
    site {
      siteMetadata {
        landingPageTitle
      }
    }
    wordpressPage(id: { eq: $id }) {
      content
      blocks {
        ...WPBlock
        innerBlocks {
          ...WPInnerBlock
        }
      }
      meta {
        media
      }
      acf {
        header {
          title
        }
        solutions {
          __typename
          ... on wordpress__PAGEAcfSolutions {
            page
            title
          }
        }
        announcements {
          ... on wordpress__PAGEAcfAnnouncements {
            page
            title
            shortTitle
            btnLink
            btnText
          }
        }
        headerVideo {
          headerVideoMP4 {
            localFile {
              ...VideoFields
            }
          }
          headerVideoWebM {
            localFile {
              ...VideoFields
            }
          }
          headerImage {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    allWordpressPost(sort: { fields: date, order: DESC }, limit: 3) {
      edges {
        node {
          ...PostFields
        }
      }
    }
  }
`
