import { Router as PreviewRouter } from '@reach/router'
import dayjs from 'dayjs'
import React, { Component } from 'react'
import Spinner from 'react-spinkit'
import config from '../../config'
import Layout from '../components/Layout'
import { LandingPageTemplate } from '../templates/landing-page'
import { PageTemplate } from '../templates/page'
import { BlogPostTemplate } from '../templates/post'
import { ServicesTemplate } from '../templates/services'
import { SolutionsTemplate } from '../templates/solutions'
import { TeamTemplate } from '../templates/team'
import { stripHTML } from '../utils/helpers'
import WP from '../utils/WP'
import NotFoundPage from './404'

function determineTemplate(page, options) {
  switch (page.acf.template) {
    case 'landing-page':
      return (
        <LandingPageTemplate
          posts={[]} // TODO: get 3 posts and pass in here
          content={page.content}
          blocks={page.blocks}
          meta={page.meta}
          acf={page.acf}
          gatsbyImage={false}
        />
      )
    case 'team':
      return <TeamTemplate team={options.team} />
    case 'services':
      return <ServicesTemplate acf={page.acf} />
    case 'solutions':
      return <SolutionsTemplate solutions={options.solutions} />
    default:
      return (
        <PageTemplate
          acf={page.acf}
          title={page.title}
          blocks={page.blocks}
          content={page.content}
          meta={page.meta}
          path={page.path}
          isPreview={true}
          isSolution={page.acf.type === 'Solution'}
          solutions={options.solutions}
        />
      )
  }
}
class PreviewPage extends Component {
  state = {
    loading: true,
    options: null,
    post: null,
    status: null,
    error: null,
  }

  async componentDidMount() {
    const { location, id, nonce } = this.props
    const url = new URL(location.href)
    const wp = new WP(nonce)

    const type = url.searchParams.get('type')
    const status = url.searchParams.get('status')

    try {
      const [postData, { acf: optionsData }] = await Promise.all([
        type === 'page' ? wp.pageWithId(id, true) : wp.postWithId(id, true),
        wp.options(),
      ])

      this.setState({
        loading: false,
        options: optionsData,
        status,
        post: {
          ...postData,
          title: postData.title.rendered,
          content: postData.content.rendered,
        },
      })
    } catch (err) {
      console.error(err)
      this.setState({
        loading: false,
        error: {
          message: 'You are not authorized to view this preview',
          type: 403,
        },
      })
    }
  }

  render() {
    const { loading, post, error, options, status } = this.state

    if (loading)
      return (
        <Layout>
          <section className="section mt-81 mb-32">
            <div className="container is-hcentered">
              <Spinner
                fadeIn="none"
                name="line-scale-pulse-out"
                color="#DA3B3C"
              />
            </div>
          </section>
        </Layout>
      )

    if (error)
      return (
        <NotFoundPage
          title={error.message}
          link={{
            type: 'external',
            path: `${config.wordpressUrl}/wp-admin`,
            innerText: 'Login to Wordpress',
          }}
          errorType={error.type}
        />
      )

    const { title, content, type, categories, author, date, tags } = post
    const isLanding = post.title === ''

    const PostStatusWidget = (
      <div className="status">
        <p
          className={`ta-c bold ${
            status === 'publish' ? 'has-text-success' : 'has-text-warning'
          }`}
        >
          <span className="bold has-text-dark">Preview Mode</span>
          <br />
          <span className="normal has-text-dark">Status: </span>
          {status === 'publish' ? 'Published' : 'Private'} <br />
          <a
            className="link"
            href={`${config.wordpressUrl}/wp-admin/post.php?post=${post.id}&action=edit`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Edit this page
          </a>
        </p>
      </div>
    )

    return type === 'post' ? (
      <Layout seo={{ pageTitle: `PREVIEW ${title}` }} lightText>
        {PostStatusWidget}
        <BlogPostTemplate
          wordpressUrl={config.wordpressUrl}
          featuredMedia={post.featured_media}
          blocks={post.blocks}
          content={content}
          title={title}
          categories={categories}
          tags={tags}
          twitterHandle={config.twitterHandle}
          url={config.siteUrl}
          author={author}
          date={dayjs(date).format('MMMM D, YYYY')}
          excerpt={stripHTML(post.excerpt.rendered)}
        />
      </Layout>
    ) : (
      <Layout
        seo={{ pageTitle: `PREVIEW ${title}` }}
        header={isLanding ? null : post.acf.header}
        featuredMedia={post.featured_media}
        featuredMediaUsesGatsbyImage={false}
        lightText
      >
        {PostStatusWidget}
        {determineTemplate(post, options)}
      </Layout>
    )
  }
}

const PreviewApp = () => (
  <PreviewRouter>
    <PreviewPage path="/preview/:id/:nonce" />
  </PreviewRouter>
)

export default PreviewApp
