import React, { useEffect, useState } from 'react'
import { extractPathFromWordpressUrl } from '../utils/helpers'

export const Announcement = ({ title, shortTitle, page, btnText = "Learn more", btnLink }) => {
  const pagePath = page ? extractPathFromWordpressUrl(page) : null;

  return (
    <div className="relative bg-dfs z-50">
      <div className="container items-center py-4 px-3 sm:px-6 lg:px-8">
        <div className="text-center sm:px-16">
          <p className="font-medium text-xl text-white">
            <span className="md:hidden">{shortTitle}</span>
            <span className="hidden md:inline">{title}</span>
            <span className="ml-2 inline-block">
              <a href={pagePath || btnLink} className="text-white font-bold underline">
                {' '}
                {btnText} <span aria-hidden="true">&rarr;</span>
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

export const Announcements = ({ announcements }) => {
  const contentIntervalSeconds = 10
  const [currentAnnouncementIndex, setCurrentAnnouncementIndex] = useState(0)

  useEffect(() => {
    if (announcements.length > 1) {
      const interval = setInterval(() => {
        const nextIndex = currentAnnouncementIndex + 1
        setCurrentAnnouncementIndex(nextIndex % announcements.length)
      }, contentIntervalSeconds * 1000)

      return () => {
        clearInterval(interval)
      }
    }
  })

  const currentAnnouncement = announcements[currentAnnouncementIndex]

  if (!currentAnnouncement) return null

  return (
    <Announcement
      key={currentAnnouncement.shortTitle}
      title={currentAnnouncement.title}
      shortTitle={currentAnnouncement.shortTitle}
      page={currentAnnouncement.page}
      btnLink={currentAnnouncement.btnLink}
      btnText={currentAnnouncement.btnText}
    />
  )
}
