import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Layout from '../components/Layout'
import WithCallToAction from '../components/WithCallToAction'
import { trunc } from '../utils/helpers'

const SHORT_DESCRIPTION_LENGTH = 314

function shortenDescription(description) {
  return trunc(description, SHORT_DESCRIPTION_LENGTH)
}

function Service({ icon, name, description }) {
  const [expanded, setExpanded] = useState(false)
  const isDescriptionLong = description.length > SHORT_DESCRIPTION_LENGTH

  return (
    <div className="card-outer h-full">
      <div className="card--with-icon">
        <div className="card-content space-y-4 ">
          <div className="flex items-center justify-center">
            <div
              className="has-background-primary inline-block p-8 rouned-full"
              style={{
                borderRadius: '50%',
              }}
              dangerouslySetInnerHTML={{ __html: icon }}
            />
          </div>

          <h3 className="text-center">{name}</h3>
          <p className="description">
            {expanded ? description : shortenDescription(description)}
          </p>
          {isDescriptionLong && (
            <span
              role="button"
              tabIndex="-1"
              className="expand-text-btn"
              onClick={() => setExpanded(!expanded)}
              onKeyDown={() => {}}
            >
              {expanded ? 'Show less' : 'Read more'}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export const ServicesTemplate = ({ acf }) => (
  <WithCallToAction>
    <div role="main">
      <section className="section">
        <div className="container content">
          <div className="grid grid-cols-3 gap-6">
            {acf.services.map(({ id, icon, name, description }) => (
              <Service
                key={id}
                icon={icon}
                name={name}
                description={description}
              />
            ))}
          </div>
        </div>
      </section>
    </div>
  </WithCallToAction>
)

const ServicesPage = ({ data }) => {
  const {
    wordpressPage: { title, acf, featured_media: featuredMedia },
  } = data

  return (
    <Layout
      header={acf.header}
      seo={{ pageTitle: title, pageDescription: acf.description }}
      featuredMedia={featuredMedia}
      lightText
    >
      <ServicesTemplate acf={acf} />
    </Layout>
  )
}

ServicesPage.propTypes = {
  data: PropTypes.shape({
    wordpressPage: PropTypes.shape({
      content: PropTypes.string,
      featured_media: PropTypes.object,
      acf: PropTypes.object,
    }),
  }),
}

export default ServicesPage

export const pageQuery = graphql`
  query ServicesPageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      id
      title
      content
      featured_media {
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      acf {
        header {
          title
          subtitle
        }
        description
        services {
          id: uID
          name
          description
          icon
        }
      }
    }
  }
`
